import Image from 'next/image'
import { useRouter } from 'next/router'
import { useState } from 'react'

import $sentry from '~/lib/sentry'
import { BusinessI } from '~/types'
import { GetCustomerShortNames, setCurrentBusiness } from '~/utils'
import { useAuth } from '../Auth/AuthProvider'
import { PopupBusinessForm } from './PopupBusinessForm'

interface BusinessCardI {
    business: BusinessI
    type?: 'listing' | 'single'
}

export const BusinessCard = ({ business, type = 'single' }: BusinessCardI) => {
    const { loadProfile, checkAccess } = useAuth()
    const router = useRouter()
    const [showBusinessForm, setShowBusinessForm] = useState(false)

    const handleClick = () => {
        if (type === 'single') {
            if (checkAccess('changeBusinessProfile')) {
                setShowBusinessForm(true)
            }
            return
        }

        setCurrentBusiness(business.businessId)
        router.push(`/business/product`)
    }
    return (
        <>
            <div
                className="flex items-center hover:bg-primary-50 transition-colors p-4 border rounded-lg"
                role="button"
                onClick={handleClick}>
                <div className="flex items-center justify-center mr-4">
                    {business.imageUrl === '' ? (
                        <div className="bg-primary-100 flex items-center justify-center rounded-lg h-12 w-12 font-bold text-neutrals-900">
                            {GetCustomerShortNames(business.name)}
                        </div>
                    ) : business.imageUrl === 'test.path' ? (
                        <></>
                    ) : (
                        <div className="bg-primary-100 flex items-center justify-center rounded-lg h-12 w-12 font-bold text-neutrals-900 overflow-hidden">
                            <Image
                                src={business.imageUrl}
                                width={48}
                                height={48}
                                alt={business.name}
                                unoptimized
                                onError={() =>
                                    $sentry.sendSentryEvent('Error loading image: ' + business.imageUrl)
                                }></Image>
                        </div>
                    )}
                </div>
                <h3 className="text-neutrals-900 font-medium">{business.name}</h3>
            </div>

            <PopupBusinessForm
                show={showBusinessForm}
                onSave={async () => {
                    await loadProfile()
                    setShowBusinessForm(false)
                }}
                onClose={() => setShowBusinessForm(false)}
                data={business}></PopupBusinessForm>
        </>
    )
}
